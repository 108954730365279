import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/DocsLayout.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Alert = makeShortcode("Alert");
const Reference = makeShortcode("Reference");
const Code = makeShortcode("Code");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`People use private CAs for all sorts of things, in many different contexts:
web apps, mobile apps, code signing, cloud VM instances, SSH, IoT devices, etc.
So `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` must be flexible enough to handle a wide variety of flows.`}</p>
    <p>{`X.509 and SSH certificate templates open up these possibilities.
With certificate templates, you can do things like:`}</p>
    <ul>
      <li parentName="ul">{`Add custom SANs or extensions to X.509 certificates`}</li>
      <li parentName="ul">{`Make longer certificate chains, with multiple intermediate CAs`}</li>
      <li parentName="ul">{`Use SSH `}<inlineCode parentName="li">{`force-command`}</inlineCode>{` or `}<inlineCode parentName="li">{`source-address`}</inlineCode>{` extensions`}</li>
      <li parentName="ul">{`Add conditionals around a certificate's parameters, and fail if they are not met`}</li>
    </ul>
    <p>{`Certificate templates are JSON documents
that describe the most important properties in the final certificate or certificate request.
They use `}<a parentName="p" {...{
        "href": "https://golang.org/pkg/text/template/"
      }}>{`Go templates`}</a>{` at the core
and are extended with `}<a parentName="p" {...{
        "href": "http://masterminds.github.io/sprig/"
      }}>{`Sprig functions`}</a>{`.`}</p>
    <p>{`A few default `}<a parentName="p" {...{
        "href": "https://github.com/smallstep/crypto/blob/master/x509util/templates.go"
      }}>{`X.509`}</a>{`
and `}<a parentName="p" {...{
        "href": "https://github.com/smallstep/crypto/blob/master/sshutil/templates.go"
      }}>{`SSH`}</a>{`
templates are hardcoded into `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`.`}</p>
    <p>{`In the next sections, we'll cover
X.509 template parameters,
SSH template parameters,
and how to configure the CA to use templates.`}</p>
    <Alert severity="info" id="star11" mdxType="Alert">
  <div>
    <strong>A note on <code>.Insecure</code> variables</strong><br />
    In templates, there are <code>.Insecure</code> variables. These are denoted as such because
    they contain information that has not been cryptographically signed
    by a source that the CA trusts.
    For example, the <code>.Insecure.CR</code> variable holds the user-supplied Certificate Request.
  </div>
    </Alert>
    <Alert severity="warning" mdxType="Alert">
  <div>
    <strong>Warning: Always wrap values in <code>toJson</code></strong><br />
    In the templates below, variables are pulled into templates using <code>{`{{`} toJson .variableName {`}}`}</code> 
    to sanitize the value of the variable.
    When using templates, you must sanitize all variables using <code>toJson</code> to avoid template injection vulnerabilities.
  </div>
    </Alert>
    <h2>{`Overview`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#x509-templates"
        }}>{`X.509 Templates`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#ssh-templates"
        }}>{`SSH Templates`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#configuring-the-ca-to-use-templates"
        }}>{`Configuring the CA to Use Templates`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#basic-x509-template-examples"
        }}>{`Basic X.509 Template Examples`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#advanced-x509-template-examples"
        }}>{`Advanced X.509 Template Examples`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#ssh-template-examples"
        }}>{`SSH Template Examples`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#even-more-templates"
        }}>{`Even More Templates`}</a></li>
    </ul>
    <h3>{`X.509 Templates`}</h3>
    <p>{`X.509 templates can be used with `}<inlineCode parentName="p">{`step certificate`}</inlineCode>{` or `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`.
Here's what the default X.509 `}<a parentName="p" {...{
        "href": "https://github.com/smallstep/crypto/blob/162770cad29063385cb768b0191814e4c6a94e45/x509util/templates.go#L98"
      }}>{`leaf certificate template`}</a>{` looks like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "subject": {{ toJson .Subject }},
    "sans": {{ toJson .SANs }},
{{- if typeIs "*rsa.PublicKey" .Insecure.CR.PublicKey }}
    "keyUsage": ["keyEncipherment", "digitalSignature"],
{{- else }}
    "keyUsage": ["digitalSignature"],
{{- end }}
    "extKeyUsage": ["serverAuth", "clientAuth"]
}
`}</code></pre>
    <h4>{`X.509 Template Variables`}</h4>
    <p>{`Here are some common variables available in X.509 certificate template:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`.Subject`}</strong>{`:
This is the subject that was passed in to `}<inlineCode parentName="p">{`step certificate`}</inlineCode>{` or `}<inlineCode parentName="p">{`step ca certificate`}</inlineCode>{`. Specifically,
`}<inlineCode parentName="p">{`.Subject.CommonName`}</inlineCode>{` contains the Common Name for the certificate.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`.SANs`}</strong>{`:
Subject Alternative Names.
This is a list of maps containing SANs for the certificate.
Unless SANs are specified (using the `}<inlineCode parentName="p">{`--san`}</inlineCode>{` flag, for example),
the `}<inlineCode parentName="p">{`.Subject.CommonName`}</inlineCode>{` is the default SAN.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`.Token`}</strong>{`:
If a signed token was used to obtain the certificate
—for example, with the JWK provisioner—
this property contains the payload of that token.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`.AuthorizationCrt`}</strong>{`:
For provisioners that use certificates to authorize requests (eg. the X5C provisioner),
this is the certificate used to authorize the current certificate request with the CA.
The client has proven posession of this certificate's private key.
The `}<inlineCode parentName="p">{`.AuthorizationCrt`}</inlineCode>{` will have the following properties: `}<inlineCode parentName="p">{`Nonce`}</inlineCode>{`, `}<inlineCode parentName="p">{`Serial`}</inlineCode>{`, `}<inlineCode parentName="p">{`Type`}</inlineCode>{`, `}<inlineCode parentName="p">{`KeyID`}</inlineCode>{`, `}<inlineCode parentName="p">{`Principals`}</inlineCode>{`, `}<inlineCode parentName="p">{`ValidBefore`}</inlineCode>{`, `}<inlineCode parentName="p">{`ValidAfter`}</inlineCode>{`, `}<inlineCode parentName="p">{`CriticalOptions`}</inlineCode>{`, `}<inlineCode parentName="p">{`Extensions`}</inlineCode>{`, and `}<inlineCode parentName="p">{`Reserved`}</inlineCode>{` (See `}<a parentName="p" {...{
            "href": "https://github.com/smallstep/crypto/blob/master/sshutil/certificate.go#L16"
          }}>{`the Certificate struct shown here`}</a>{`).`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`.AuthorizationChain`}</strong>{`:
For provisioners that use certificates to authorize requests (eg. the X5C provisioner),
this is an array of the certificate chain from the request.
This chain connects the authorization certificate to the root CA configured in the provisioner.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`.Insecure.CR`}</strong><Reference id="star11" marker="*" mdxType="Reference" />{`: ☠️
This holds the Certificate Request (CSR) received from the client.
How step-ca treats these values depends on the provisioner.
While a CSR is always passed to step-ca (often alongside a signed token),
in most cases `}<em parentName="p">{`only the public key value in the CSR is used by step-ca`}</em>{`.
These properties are marked `}<em parentName="p">{`insecure`}</em>{` because CSRs are not explicitly authenticated by step-ca.
Instead, step-ca will authenticate the signed values from OIDC tokens, JWK tokens, cloud IIDs,
and the validated SANs from ACME orders.
For ACME, step-ca validates that the CSR's subject and SANs only contain values that are in the validated ACME order.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`.Insecure.CR.DNSNames`}</strong>{`:
The DNS Name Subject Alternative Names (SANs) provided in the CSR`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`.Insecure.CR.IPAddresses`}</strong>{`:
The IP Address SANs provided in the CSR`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`.Insecure.CR.URIs`}</strong>{`:
The URI SANs provided in the CSR`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`.Insecure.CR.EmailAddresses`}</strong>{`:
The Email Address SANs provided in the CSR`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`.Insecure.CR.Subject.CommonName`}</strong>{`:
The Common Name (CN) provided in the CSR's subject`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`.Insecure.CR.Subject.ExtraNames`}</strong>{`:
The Extra Names provided in the CSR subject.
This is an array of all subject distinguished names (DNs) in the CSR.
Each contains an OID (`}<inlineCode parentName="p">{`type`}</inlineCode>{`) and a value (`}<inlineCode parentName="p">{`value`}</inlineCode>{`).`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`.Insecure.CR.PublicKey`}</strong>{`:
The public key provided in the certificate request.
You can check the request's key type with a conditional, like `}<inlineCode parentName="p">{`{{- if typeIs "*rsa.PublicKey" .Insecure.CR.PublicKey }}`}</inlineCode>{`.
You can check the request's key size with a conditional, like `}<inlineCode parentName="p">{`{{- if lt .Insecure.CR.PublicKey.Size 384 }}`}</inlineCode>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`.Insecure.User`}</strong>{`:
This property holds user-supplied data from the certificate request.
Users can supply arbitrary values using `}<inlineCode parentName="p">{`--set`}</inlineCode>{` or `}<inlineCode parentName="p">{`--set-file`}</inlineCode>{` flags in `}<inlineCode parentName="p">{`step ca certificate`}</inlineCode>{`.`}</p>
      </li>
    </ul>
    <p>{`You can also import values
from the `}<inlineCode parentName="p">{`"templateData"`}</inlineCode>{` object
in your provisioner's `}<a parentName="p" {...{
        "href": "#configuring-the-ca-to-use-templates"
      }}>{`configuration block`}</a>{`.`}</p>
    <h3>{`SSH Templates`}</h3>
    <p><inlineCode parentName="p">{`step-ca`}</inlineCode>{` also supports SSH certificate templates.
Here is `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`'s default `}<a parentName="p" {...{
        "href": "https://github.com/smallstep/crypto/blob/162770cad29063385cb768b0191814e4c6a94e45/sshutil/templates.go#L144"
      }}>{`SSH certificate template`}</a>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "type": {{ toJson .Type }},
    "keyId": {{ toJson .KeyID }},
    "principals": {{ toJson .Principals }},
    "extensions": {{ toJson .Extensions }},
    "criticalOptions": {{ toJson .CriticalOptions }}
}
`}</code></pre>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://github.com/openssh/openssh-portable/blob/master/PROTOCOL.certkeys"
      }}>{`OpenSSH certificate protocol spec`}</a>{` defines the available Critical Options and Extensions.
Third parties can also add custom extensions.`}</p>
    <h4>{`SSH Template Variables`}</h4>
    <p>{`Here are the most relevant parameters available in SSH certificate template:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`.Token`}</strong>{`:
This property offers the best opportunity for injecting signed, secure data into the certificate.
If a token was used to obtain the certificate,`}<br parentName="p"></br>{`
`}{`this property contains token payload used in the certificate request.
For example, when using an OAuth ID token,
`}<inlineCode parentName="p">{`.Token.email`}</inlineCode>{` will contain the user's email address.
To add custom data to `}<inlineCode parentName="p">{`.Token`}</inlineCode>{`, you could use custom OAuth claims, or sign your own JWTs.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`.Extensions`}</strong>{`: is a map containing extensions.
The default value for `}<inlineCode parentName="p">{`Extensions`}</inlineCode>{` is:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-json"
          }}>{`{
  "permit-X11-forwarding":   "",
  "permit-agent-forwarding": "",
  "permit-port-forwarding":  "",
  "permit-pty":              "",
  "permit-user-rc":          ""
}
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`.CriticalOptions`}</strong>{`: is a map containing critical options.
It is empty by default.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`.Principals`}</strong>{`: For users, this value is derived from the OIDC token
(when using the `}<a parentName="p" {...{
            "href": "#OAuth-OIDC-Single-Sign-on"
          }}>{`OIDC provisioner`}</a>{`).
For hosts, it is derived from the Instance Identity Document
(when using the `}<a parentName="p" {...{
            "href": "#cloud-provisioners"
          }}>{`Cloud provisioners`}</a>{`).`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`.KeyID`}</strong>{`:
The key ID being requested.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`.Type`}</strong>{`:
The type of SSH certificate being requested.
This will be `}<inlineCode parentName="p">{`user`}</inlineCode>{` or `}<inlineCode parentName="p">{`host`}</inlineCode>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`.Insecure.CR`}</strong><Reference id="star11" marker="*" mdxType="Reference" />{`:
SSH certificate requests to `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` are not CSRs in the X.509 sense.
So, `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` creates a virtual certificate request,
and that's what this variable represents.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`.Insecure.CR.Principals`}</strong>{`: If you trust a host to register its own custom SANs
(for example, when using the IID provisioner),
then the host can pass several `}<inlineCode parentName="p">{`--principal`}</inlineCode>{` values to `}<a parentName="p" {...{
            "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ssh/certificate"
          }}><inlineCode parentName="a">{`step ssh certificate`}</inlineCode></a>{` when registering the host,
and use `}<inlineCode parentName="p">{`.Insecure.CR.Principals`}</inlineCode>{` to access those from a template.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`.Insecure.CR.Type`}</strong>{`:
The type of SSH certificate being requested.
This will be `}<inlineCode parentName="p">{`user`}</inlineCode>{` or `}<inlineCode parentName="p">{`host`}</inlineCode>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`.Insecure.CR.KeyID`}</strong>{`:
The key ID being requested.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`.Insecure.User`}</strong>{`: This property holds user-supplied data from the certificate request.
Users can supply arbitrary values using `}<inlineCode parentName="p">{`--set`}</inlineCode>{` or `}<inlineCode parentName="p">{`--set-file`}</inlineCode>{` flags in `}<a parentName="p" {...{
            "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ssh/certificate"
          }}><inlineCode parentName="a">{`step ssh certificate`}</inlineCode></a>{`.`}</p>
      </li>
    </ul>
    <p>{`You can also import parameter values
from your provisioner's `}<a parentName="p" {...{
        "href": "#configuring-the-ca-to-use-templates"
      }}>{`configuration block`}</a>{`.`}</p>
    <Alert severity="warning" mdxType="Alert">
  <div>
    <strong>An OIDC Caveat</strong><br />
    If you've added an OIDC admin user to your CA configuration,
    and you add an SSH certificate template,
    you will lose the OIDC provisioner admin privilege of being able to supply arbitrary <code>--principal</code> values when requesting an SSH certificate.
    This is because the CA usually uses a different <a href="https://github.com/smallstep/crypto/blob/162770cad29063385cb768b0191814e4c6a94e45/sshutil/templates.go#L152">default template for admin users</a>.
  </div>
    </Alert>
    <h3>{`Configuring the CA to Use Templates`}</h3>
    <p>{`Within provisioner configuration, certificate templates can be set
under the property `}<inlineCode parentName="p">{`"options"`}</inlineCode>{`.
The following snippet shows a provisioner with custom X.509 and SSH templates:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "type": "JWK",
  "name": "jane@doe.com",
  "key": {
      "use": "sig",
      "kty": "EC",
      "kid": "lq69QCCEwEhZys_wavar9RoqRLdJ58u_OGzJK0zswSU",
      "crv": "P-256",
      "alg": "ES256",
      "x": "pt7T0n98qREZUkyUX6b4kXJ5FkJlIdiMfJaLFclZIng",
      "y": "Pw1y1xqe4g4YARwyBSkEkcjNrtPYxdKlYDLI512t2_M"
  },
  "encryptedKey": "eyJhbGciOiJQQkVTMi1IUzI1NitBMTI4S1ciLCJjdHkiOiJqd2sranNvbiIsImVuYyI6IkEyNTZHQ00iLCJwMmMiOjEwMDAwMCwicDJzIjoiQ1dWZG5zWTR2bGZJbG9BQ1dOaUdNUSJ9.12dowlkvESpxJgrNJgP2ELDQz750HSh2w6Ux6BmatBE5-ybAJFFO7g.1cjU2-CTrV3gbUE7.m8a95nv4qLnN_K_PG7lzgzYXBGnw_aHCf-znJ34AZxzPy2QDGGEjN_V0jX3kvHH9AIg3cs8I8NRm__RDm2iezU5AhPoAHaqnPnZdKh0ReBZ4hNpYXUHlTPf4fRaCXXDQiKatxNzCMBpyqKpudf3xYUzZPRNMN78WM0ZeIzmv_jFzbryOpxD8bJ3Bnxa4e8Am_pPdAANHphodlKN2nDr4701OLKgitImm8RoA20sWdAI_LkTS_Abk_TqBo_3qOGdjmnRBtATFSu3BdQw5wZMjywfwCWKXUK_OUt-cjVIe11xUT43SoE8fR2GJJEKomAHP0vn0MUUMqY9P9icUejw.eEYI_H7WfrYDL4yhsnsJxg",
  "claims": {
    "enableSSHCA": true
  }
  "options": {
    "x509": {
      "templateFile": "templates/certs/x509/leaf.tpl",
      "templateData": {
        "OrganizationalUnit": "Engineering Team"
      }
    },
    "ssh": {
      "templateFile": "templates/certs/ssh/host.tpl"
    }
  }
}
`}</code></pre>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`options`}</strong>{`: object that allows configuration of provisioner options e.g. templates.`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li"><strong parentName="p">{`x509`}</strong>{` or `}<strong parentName="p">{`ssh`}</strong>{`: object for configuring X.509 or SSH certificate template options.`}</p>
            <ul parentName="li">
              <li parentName="ul">
                <p parentName="li"><strong parentName="p">{`templateFile`}</strong>{`: path to a template stored on disk. You have a few options for how to define your path:`}</p>
                <ul parentName="li">
                  <li parentName="ul"><strong parentName="li"><em parentName="strong">{`absolute path`}</em></strong>{`:  e.g. `}<inlineCode parentName="li">{`/home/mariano/path/to/file.ptl`}</inlineCode></li>
                  <li parentName="ul"><strong parentName="li"><em parentName="strong">{`relative to `}<inlineCode parentName="em">{`$(step path)`}</inlineCode></em></strong>{`: e.g. `}<inlineCode parentName="li">{`templates/certs/x509/leaf.tpl`}</inlineCode>{` the actual
location of which would be `}<inlineCode parentName="li">{`$(step path)/templates/certs/x509/leaf.tpl`}</inlineCode>{`.`}</li>
                  <li parentName="ul"><strong parentName="li"><em parentName="strong">{`relative to the execution directory of `}<inlineCode parentName="em">{`step-ca`}</inlineCode></em></strong>{`: e.g. `}<inlineCode parentName="li">{`./path/to/file.tpl`}</inlineCode>{` or `}<inlineCode parentName="li">{`../path/to/file.tpl`}</inlineCode></li>
                </ul>
              </li>
              <li parentName="ul">
                <p parentName="li"><strong parentName="p">{`templateData`}</strong>{`: defines variables that can be used in the template.
In the example above, you will be able to use the defined organizational unit as the variable `}<inlineCode parentName="p">{`{{ .OrganizationalUnit }}`}</inlineCode>{`,
for example in a template like:`}</p>
                <pre parentName="li"><code parentName="pre" {...{
                    "className": "language-json"
                  }}>{`{
    "subject": {
        "organizationalUnit": {{ toJson .OrganizationalUnit }},
        "commonName": {{ toJson .Subject.CommonName }}
    },
    "sans": {{ toJson .SANs }},
    "keyUsage": ["digitalSignature"],
    "extKeyUsage": ["serverAuth", "clientAuth"]
}
`}</code></pre>
              </li>
              <li parentName="ul">
                <p parentName="li"><strong parentName="p">{`template`}</strong>{`: set the full template in a string. The value can be the
string representation of a JSON object, or you encoded in base64. For
example: `}</p>
                <pre parentName="li"><code parentName="pre" {...{
                    "className": "language-json"
                  }}>{`{
    "x509": {
        "template": "{{ toJson .Insecure.CR }}",
    }
}
`}</code></pre>
                <p parentName="li">{`  Or using base64:`}</p>
                <pre parentName="li"><code parentName="pre" {...{
                    "className": "language-json"
                  }}>{`{
    "x509": {
        "template": "e3sgdG9Kc29uIC5JbnNlY3VyZS5DUiB9fQ==",
    }
}
`}</code></pre>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <h3>{`Basic X.509 Template Examples`}</h3>
    <Alert severity="info" mdxType="Alert">
  <div>
    <strong>Here's an easy way to test X.509 templates</strong><br />
    You can create <a href="/docs/step-ca/certificate-authority-core-concepts#online-and-offline-x509-certificate-authority">a stateless offline CA</a> with <a href="/docs/step-cli/reference/certificate"><code>step certificate</code></a>,
    and pass <code>--template &lt;path&gt;</code> to the <a href="/docs/step-cli/reference/certificate/create"><code>step certificate create</code></a> or <a href="/docs/step-cli/reference/certificate/sign"><code>step certificate sign</code></a> subcommands.
  </div>
    </Alert>
    <h4>{`Leaf Certificates`}</h4>
    <p>{`The most common type of certificate you'll want to create is a leaf (or end-entity)
certificate; this is the type of certificate that your servers or clients will
use.`}</p>
    <p>{`The default leaf certificate template is:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "subject": {{ toJson .Subject }},
    "sans": {{ toJson .SANs }},
{{- if typeIs "*rsa.PublicKey" .Insecure.CR.PublicKey }}
    "keyUsage": ["keyEncipherment", "digitalSignature"],
{{- else }}
    "keyUsage": ["digitalSignature"],
{{- end }}
    "extKeyUsage": ["serverAuth", "clientAuth"]
}
`}</code></pre>
    <p>{`We will explain what each block does, but first, let's look at what a rendered
template might look like. Imagine that you run the following command to create
a certificate that has `}<inlineCode parentName="p">{`jane@doe.com`}</inlineCode>{` as an email SAN.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step ca certificate jane@doe.com jane.crt
`}</code></pre>
    <p>{`The rendered template (from which the X.509 certificate will be generated and
signed) is:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "subject": {
        "commonName": "jane@smallstep.com"
    },
    "sans": [{"type": "email", "value": "jane@smallstep.com"}],
    "keyUsage": ["digitalSignature"],
    "extKeyUsage": ["serverAuth", "clientAuth"]
}
`}</code></pre>
    <p>{`Going back to the template above, we can see that the template has blocks
delimited by `}<inlineCode parentName="p">{`{{`}</inlineCode>{` and `}<inlineCode parentName="p">{`}}`}</inlineCode>{`. These are called `}<strong parentName="p"><em parentName="strong">{`actions`}</em></strong>{` - `}<strong parentName="p"><em parentName="strong">{`actions`}</em></strong>{` are
data evaluations or control structures. The ones in the default template are:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`{{ toJson .Subject }}`}</inlineCode>{`: renders `}<inlineCode parentName="p">{`.Subject`}</inlineCode>{` as JSON. `}<inlineCode parentName="p">{`toJson`}</inlineCode>{` is a function in
Sprig that encodes the passed item into JSON. `}<inlineCode parentName="p">{`.Subject`}</inlineCode>{` is a variable available
in all templates that contains the `}<inlineCode parentName="p">{`<subject>`}</inlineCode>{` parameter passed in the CLI, in
this case, `}<inlineCode parentName="p">{`jane@smallstep.com`}</inlineCode>{`, and to be more precise, this value is available
in `}<inlineCode parentName="p">{`.Subject.CommonName`}</inlineCode>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`{{ toJson .SANs }}`}</inlineCode>{`: renders `}<inlineCode parentName="p">{`.SANs`}</inlineCode>{` (Subject Alternative Names) as JSON.
The variable `}<inlineCode parentName="p">{`.SANs`}</inlineCode>{` is also available in all templates and contains the list
of `}<inlineCode parentName="p">{`SANs`}</inlineCode>{` passed from the CLI.  If no `}<inlineCode parentName="p">{`SANs`}</inlineCode>{` are specified, the
`}<inlineCode parentName="p">{`.Subject.CommonName`}</inlineCode>{` will be used as a default `}<inlineCode parentName="p">{`SAN`}</inlineCode>{` (e.g.
`}<inlineCode parentName="p">{`jane@smallstep.com`}</inlineCode>{` in our example). If you add more `}<inlineCode parentName="p">{`SANs`}</inlineCode>{` using the
flag `}<inlineCode parentName="p">{`--san`}</inlineCode>{`, `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` will auto-assign the correct SAN `}<inlineCode parentName="p">{`type`}</inlineCode>{` (`}<inlineCode parentName="p">{`dns`}</inlineCode>{`, `}<inlineCode parentName="p">{`ip`}</inlineCode>{`,
`}<inlineCode parentName="p">{`uri`}</inlineCode>{`, or `}<inlineCode parentName="p">{`email`}</inlineCode>{`).`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><inlineCode parentName="p">{`{{- if typeIs "*rsa.PublicKey" .Insecure.CR.PublicKey }}`}</inlineCode>{`: defines a
condition based on the key type in the certificate request. `}<inlineCode parentName="p">{`typeIs`}</inlineCode>{`
is a Sprig function that returns true if the type of the data in
`}<inlineCode parentName="p">{`.Insecure.CR.PublicKey`}</inlineCode>{` matches `}<inlineCode parentName="p">{`*rsa.PublicKey`}</inlineCode>{`, this is the Go specific type
for RSA public keys. If the condition is true, the `}<inlineCode parentName="p">{`keyUsage`}</inlineCode>{` is set to
`}<inlineCode parentName="p">{`keyEncipherment`}</inlineCode>{` and `}<inlineCode parentName="p">{`digitalSignature`}</inlineCode>{`, otherwise just `}<inlineCode parentName="p">{`digitalSignature`}</inlineCode>{`.
`}<inlineCode parentName="p">{`keyEncipherment`}</inlineCode>{` is used in the RSA key exchange protocol,
and it must only be set if an RSA key is used. `}<inlineCode parentName="p">{`.Insecure.CR`}</inlineCode>{` is a structure that
represents the certificate (signing) request or CSR, from it we can extract
SANs, the subject, extensions, etc. `}</p>
      </li>
    </ul>
    <p>{`Finally we also set the extended key usage `}<inlineCode parentName="p">{`serverAuth`}</inlineCode>{` and `}<inlineCode parentName="p">{`clientAuth`}</inlineCode>{` so the
certificate can be used in a server application or in a client for mTLS.`}</p>
    <h4>{`Intermediate Certificates`}</h4>
    <p>{`An intermediate certificate is a certificate that can be used to sign another
certificate, and it has itself been signed by another intermediate certificate or by
a root certificate.`}</p>
    <p>{`The default template for an intermediate certificate is:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "subject": {{ toJson .Subject }},
    "keyUsage": ["certSign", "crlSign"],
    "basicConstraints": {
        "isCA": true,
        "maxPathLen": 0
    }
}
`}</code></pre>
    <p>{`This template is most commonly used when initializing a CA.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`keyUsage`}</inlineCode>{` `}<strong parentName="p"><em parentName="strong">{`certSign`}</em></strong>{` allows a certificate to sign other certificates.
`}<strong parentName="p"><em parentName="strong">{`crlSign`}</em></strong>{` allows a certificate to sign Certificate Revocation Lists or CRLs.`}</p>
    <p>{`In `}<inlineCode parentName="p">{`basicConstraints`}</inlineCode>{` we say that this certificate is a CA, and the path length
constraint, `}<inlineCode parentName="p">{`maxPathLen`}</inlineCode>{`, is maximum number of non-self-issued intermediate
certificates that may follow this certificate in a valid certification path. An
intermediate with a `}<inlineCode parentName="p">{`maxPathLen`}</inlineCode>{` set to `}<inlineCode parentName="p">{`0`}</inlineCode>{` won't be able to sign another
intermediate certificate. To be precise, it can sign it, but the new
intermediate won't be trusted by clients, so it won't be useful.`}</p>
    <p>{`Knowing this we can also guess that the intermediate certificate's issuer had at
least a `}<inlineCode parentName="p">{`maxPathLen`}</inlineCode>{` of `}<inlineCode parentName="p">{`1`}</inlineCode>{`. So if you want to use an intermediate certificate
that can sign other intermediates, make sure to set more permissive `}<inlineCode parentName="p">{`maxPathLen`}</inlineCode>{`
in the root and intermediate certificate used.`}</p>
    <p>{`The command `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/certificate/create"
      }}><inlineCode parentName="a">{`step certificate create`}</inlineCode></a>{` with the `}<inlineCode parentName="p">{`--profile intermediate-ca`}</inlineCode>{` flag
uses the template above, but if you want to create an intermediate with a custom
template you can run:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step certificate create --template intermediate.tpl \\
--ca ~/.step/certs/root_ca.crt --ca-key ~/.step/secrets/root_ca_key \\
"ACME Intermediate CA" intermediate_ca.crt intermediate_ca_key
`}</code></pre>
    <h4>{`Root Certificates`}</h4>
    <p>{`A root certificate is a self-signed certificate used to sign other certificates.
The default root certificate template is:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "subject": {{ toJson .Subject }},
    "issuer": {{ toJson .Subject }},
    "keyUsage": ["certSign", "crlSign"],
    "basicConstraints": {
        "isCA": true,
        "maxPathLen": 1
    }
}
`}</code></pre>
    <p>{`Here we are specifying that the `}<strong parentName="p"><em parentName="strong">{`issuer`}</em></strong>{` is equivalent to the `}<strong parentName="p"><em parentName="strong">{`subject`}</em></strong>{`, and the `}<inlineCode parentName="p">{`maxPathLen`}</inlineCode>{` is set to
`}<inlineCode parentName="p">{`1`}</inlineCode>{`, so it can sign intermediate certificates with `}<inlineCode parentName="p">{`maxPathLen`}</inlineCode>{` set to `}<inlineCode parentName="p">{`0`}</inlineCode>{`. If
you want to allow intermediates to sign other intermediates, you need to set the
`}<inlineCode parentName="p">{`maxPathLen`}</inlineCode>{` to at least `}<inlineCode parentName="p">{`2`}</inlineCode>{`. It's also possible to not limit the number of
intermediates that can follow this certificate if we set `}<inlineCode parentName="p">{`maxPathLen`}</inlineCode>{` to `}<inlineCode parentName="p">{`-1`}</inlineCode>{`,
and then create intermediates that set a specific limit, i.e. `}<inlineCode parentName="p">{`0`}</inlineCode>{` for those that
can sign only leaf certificates and `}<inlineCode parentName="p">{`1`}</inlineCode>{` for those that can sign at most one downstream
intermediate certificate.`}</p>
    <p>{`The command `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/certificate/create"
      }}><inlineCode parentName="a">{`step certificate create`}</inlineCode></a>{` with the `}<inlineCode parentName="p">{`--profile root-ca`}</inlineCode>{` flag uses the
above template. To create a custom root certificate, you can run:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step certificate create --template root.tpl "ACME Root CA" root_ca.crt root_ca_key
`}</code></pre>
    <Alert severity="info" mdxType="Alert">
  <div>
    It is not possible to use <Code mdxType="Code">step-ca</Code> to sign root certificates.
	And, <Code mdxType="Code">step-ca</Code> cannot sign intermediates directly from its root for clients.
  </div>
    </Alert>
    <h3>{`Advanced X.509 Template Examples`}</h3>
    <p>{`In the previous section,
we learned how to create and use the basic templates,
but we've just scratched the surface of what's possible.
With templates, you can customize and parameterize every aspect of an X.509
or SSH certificate.`}</p>
    <p>{`Below, we'll walk through a few advanced templating examples.`}</p>
    <h4>{`Certificate Request`}</h4>
    <p>{`Let's start with one of the shortest templates:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{{ toJson .Insecure.CR }}
`}</code></pre>
    <p>{`This template produces a signed certificate that contains all the information
present in the certificate request (with a few caveats). All SANs and
extensions present in the certificate request will be in the final certificate.`}</p>
    <p>{`The only modifications that a signed certificate might have (over a CSR), would be in the
certificate subject on `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` if a template is provided in the `}<inlineCode parentName="p">{`ca.json`}</inlineCode>{`, and
it will set the Subject Alternative Name extension to critical if the subject is
empty.`}</p>
    <h4>{`User-Provided Variables in Signing Requests`}</h4>
    <p>{`In `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`, X.509 templates can also be parameterized with variables that will be
provided by `}<inlineCode parentName="p">{`step`}</inlineCode>{` at certificate creation time. A common use case for
variables is when you receive a CSR from another team, or a CSR embedded in
hardware, and you need to define a SAN for it.`}</p>
    <p>{`For example, below is an X.509 template that accepts the user variable `}<inlineCode parentName="p">{`dnsName`}</inlineCode>{`
but falls back to the default leaf template if it's not present:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "subject": {{ toJson .Subject }},
{{- if .Insecure.User.dnsName }}
    "dnsNames": {{ toJson .Insecure.User.dnsName}},
{{- else }}
    "sans": {{ toJson .SANs }},
{{- end }}
{{- if typeIs "*rsa.PublicKey" .Insecure.CR.PublicKey }}
    "keyUsage": ["keyEncipherment", "digitalSignature"],
{{- else }}
    "keyUsage": ["digitalSignature"],
{{- end }}
    "extKeyUsage": ["serverAuth", "clientAuth"]
}
`}</code></pre>
    <p>{`You can then use `}<inlineCode parentName="p">{`step`}</inlineCode>{` to sign a CSR like so:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ step ca sign --set "dnsName=backend.example.com" input.csr output.crt
# Or
$ echo '{"dnsName": "backend.example.com"}' > vars.json
$ step ca sign --set-file vars.json input.csr output.crt
`}</code></pre>
    <p>{`Both flags, `}<inlineCode parentName="p">{`--set <name=value>`}</inlineCode>{` and `}<inlineCode parentName="p">{`--set-file <path>`}</inlineCode>{` are available in
`}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ca/certificate"
      }}><inlineCode parentName="a">{`step ca certificate`}</inlineCode></a>{` and `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ca/sign"
      }}><inlineCode parentName="a">{`step ca sign`}</inlineCode></a>{`. If you need to pass more than one
variable, you can use `}<inlineCode parentName="p">{`--set`}</inlineCode>{` multiple times or use a JSON file with multiple
properties.`}</p>
    <p>{`It's worth mentioning the while we used `}<inlineCode parentName="p">{`"dnsNames"`}</inlineCode>{` instead of `}<inlineCode parentName="p">{`"sans"`}</inlineCode>{` in the example above, both can
be used. `}<inlineCode parentName="p">{`"dnsNames"`}</inlineCode>{` is an array of strings (or just one string if only one is
required), while `}<inlineCode parentName="p">{`"sans"`}</inlineCode>{` is an array of objects like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`[
    {"type": "dns", "value": "backend.example.com"},
    {"type": "email", "value": "jane@example.com"},
    {"type": "ip", "value": "10.0.1.10"},
    {"type": "uri", "value": "https://backend.example.com/root.crt"},
]
`}</code></pre>
    <p>{`The variable `}<inlineCode parentName="p">{`.SANs`}</inlineCode>{` is generated by the provisioners with the values of the
trusted names.`}</p>
    <p>{`Besides `}<inlineCode parentName="p">{`"dnsNames"`}</inlineCode>{`, you can also use `}<inlineCode parentName="p">{`"emailAddresses"`}</inlineCode>{`, `}<inlineCode parentName="p">{`"ipAddresses"`}</inlineCode>{`, and
`}<inlineCode parentName="p">{`"uris"`}</inlineCode>{`.`}</p>
    <h4>{`Adding Name Constraints`}</h4>
    <p>{`Name constraints are useful in intermediate certificates when you want to limit
the validity of certificates that an intermediate can sign. If we want to only
allow DNS name like `}{`*`}{`.example.com we can generate an intermediate with the
template:`}</p>
    <pre><code parentName="pre" {...{}}>{`{
    "subject": {{ toJson .Subject }},
    "keyUsage": ["certSign", "crlSign"],
    "basicConstraints": {
        "isCA": true,
        "maxPathLen": 0
    },
    "nameConstraints": {
        "critical": true,
        "permittedDNSDomains": ["example.com"]
    }
}
`}</code></pre>
    <p>{`Given a root certificate and a key, you can generate the intermediate with:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step certificate create —template intermediate.tpl --ca root_ca.crt --ca-key root_ca_key \\
     “Intermediate CA” intermediate.crt intermediate.key
`}</code></pre>
    <p>{`Besides `}<inlineCode parentName="p">{`"permittedDNSDomains"`}</inlineCode>{`, the `}<inlineCode parentName="p">{`"nameConstraints"`}</inlineCode>{` property accepts all
the following properties:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "nameConstraints": {
        "critical": false,
        "permittedDNSDomains": ["doe.com"],
        "excludedDNSDomains": ["doe.org"],
        "permittedIPRanges": ["1.2.3.0/24"],
        "excludedIPRanges": ["3.2.1.0/24"],
        "permittedEmailAddresses": ["jane@doe.com"],
        "excludedEmailAddresses": ["jane@doe.org"],
        "permittedURIDomains": ["https://doe.com"],
        "excludedURIDomains": ["https://doe.org"],
    }
}
`}</code></pre>
    <p>{`Remember that in certificate templates, if an array only has one member, you can
write it as a string:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "nameConstraints": {
        "critical": true,
        "permittedDNSDomains": "example.com"
    }
}
`}</code></pre>
    <h4>{`Arbitrary X.509 Extensions`}</h4>
    <p>{`SANs, key usages, extended key usages, basic constraints, and name constraints,
are all extensions in an X.509 certificate. Templates provide an
easy way for assigning extensions without the need of using the actual extension
bytes.`}</p>
    <p>{`The same thing is also possible with top-level certificate template properties like:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`unknownExtKeyUsage`}</strong>{`: defines custom extended key usages using ASN.1
object identifiers; this is useful if the templates do not support a string
version of the extended key usage `}<a parentName="p" {...{
            "href": "https://tools.ietf.org/html/rfc5280#section-4.2.1.12"
          }}>{`(RFC 5280, 4.2.1.12)`}</a>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`ocspServer`}</strong>{`: sets a list of OCSP servers in the Authority Information
Access extension `}<a parentName="p" {...{
            "href": "https://tools.ietf.org/html/rfc5280#section-4.2.2.1"
          }}>{`(RFC 5280, 4.2.2.1)`}</a>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`issuingCertificateURL`}</strong>{`: defines the issuing certificate URL in the Authority
Information Access extension `}<a parentName="p" {...{
            "href": "https://tools.ietf.org/html/rfc5280#section-4.2.2.1"
          }}>{`(RFC 5280, 4.2.2.1)`}</a>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`crlDistributionPoints`}</strong>{`: defines URLs with the certificate revocation
list `}<a parentName="p" {...{
            "href": "https://tools.ietf.org/html/rfc5280#section-4.2.1.13"
          }}>{`(RFC 5280, 4.2.1.13)`}</a>{`).`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`policyIdentifiers`}</strong>{`: defines a list of ASN.1 object identifiers with
certificate policies `}<a parentName="p" {...{
            "href": "https://tools.ietf.org/html/rfc5280#section-4.2.1.4"
          }}>{`(RFC 5280, 4.2.1.4)`}</a>{`.`}</p>
      </li>
    </ul>
    <p>{`But if you need to create your own extension, or use an unsupported one, you can
also write a custom extension like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "extensions": [
        {"id": "1.2.3.4", "critical": false, "value": "Y3VzdG9tIGV4dGVuc2lvbiB2YWx1ZQ=="}
    ]
}
`}</code></pre>
    <p>{`The crux here is that the value of the extension is the base64 encoding of the
actual bytes that go into that extension, so if you are encoding a structure
in your extension using the ASN.1 encoding, you will have to put the base64
version of the encoded bytes.`}</p>
    <h4>{`X.509 OpenVPN certificates`}</h4>
    <p>{`One interesting use case for certificate templates is the signing of
`}<a parentName="p" {...{
        "href": "https://openvpn.net"
      }}>{`OpenVPN`}</a>{` certificates. These certificates require specific
key usages not available in the default templates.`}</p>
    <p>{`This is a template you can use in a provisioner signing OpenVPN client certificates:`}</p>
    <pre><code parentName="pre" {...{}}>{`{
  "subject": {"commonName": {{ toJson .Insecure.CR.Subject.CommonName }}},
  "sans": {{ toJson .SANs }},
  "keyUsage": ["digitalSignature", "keyAgreement"],
  "extKeyUsage": ["clientAuth"]
}
`}</code></pre>
    <p>{`And the following template can be used for a provisioner signing OpenVPN server
certificates:`}</p>
    <pre><code parentName="pre" {...{}}>{`{
  "subject": {{ toJson .Subject }},
  "sans": {{ toJson .SANs }},
  "keyUsage": ["digitalSignature", "keyEncipherment", "keyAgreement"],
  "extKeyUsage": ["serverAuth"]
}
`}</code></pre>
    <p>{`A full configuration of `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` and OpenVPN is available in our blog post
`}<a parentName="p" {...{
        "href": "https://smallstep.com/blog/x509-certificate-flexibility/"
      }}>{`"Announcing X.509 Certificate
Flexibility"`}</a></p>
    <h3>{`SSH Template Examples`}</h3>
    <h4>{`GitHub SSH Certificates`}</h4>
    <p>{`With GitHub Enterprise Cloud or GitHub Enterprise Server, you can configure GitHub to use SSH certificates.
Specifically, GitHub will `}<a parentName="p" {...{
        "href": "https://docs.github.com/en/free-pro-team@latest/github/setting-up-and-managing-organizations-and-teams/about-ssh-certificate-authorities"
      }}>{`trust an SSH Certificate Authority for your team`}</a>{`.
But, to get it to work, you have to generate custom SSH certificates for your users
using a `}<inlineCode parentName="p">{`login@github.com`}</inlineCode>{` extension,
where the value of the extension is their GitHub Username.
This custom extension value authenticates your users to GitHub Enterprise.
Which is great: The same certificates that let you SSH into your servers now also let you push code.`}</p>
    <p>{`Here's an SSH template that supports the GitHub custom SSH certificate extension:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`{
    "type": {{ toJson .Type }},
    "keyId": {{ toJson .KeyID }},
    "principals": {{ toJson .Principals }},
    "criticalOptions": {{ toJson .CriticalOptions }},
{{ if .Token.ghu }}
    "extensions": {
      "login@github.com": {{ toJson .Token.ghu }}
    }
{{ else }}
    "extensions": {{ toJson .Extensions }}
{{ end }}
}
`}</code></pre>
    <p>{`To use this template, you'd need to add a `}<inlineCode parentName="p">{`ghu`}</inlineCode>{` ("GitHub Username") custom claim to the token that you send the CA to authenticate your certificate request.`}</p>
    <p>{`If you use an `}<a parentName="p" {...{
        "href": "#oauthoidc-single-sign-on"
      }}>{`OIDC provisioner`}</a>{` with this template,
you can configure your identity provider to serve a custom claim labeled `}<inlineCode parentName="p">{`ghu`}</inlineCode>{` in your ID token.
Our blog post `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/blog/clever-uses-of-ssh-certificate-templates/"
      }}>{`"Clever Uses of SSH Certificate Templates"`}</a>{` has more details on this option.`}</p>
    <p>{`The `}<a parentName="p" {...{
        "href": "#jwk"
      }}>{`JWK`}</a>{` and `}<a parentName="p" {...{
        "href": "#cloud-provisioners"
      }}>{`Cloud`}</a>{` provisioners also use tokens.
Here's an example of a Bash test script that
decrypts your JWK provisioner key,
and uses it to sign a token that `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` will accept for an SSH certificate:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`#!/bin/bash
#
# This script is for testing purposes only.
#

# Your CA hostname and port
CA=ca.local:443

# Key ID: This is the "kid" value from your JWK provisioner configuration
KID=R0a0VGbbOe3j6ol5jOdV3qfiigjcezk0LG9K9Cp7mrg

# Issuer: The "name" of your JWK provisioner
ISS="carl@smallstep.com"

if [ "$#" -ne 2 ]; then
    name=$(basename $0)
    echo "Usage: $name <princiap> <github-username>"
    exit 1
fi

# Grab the key to encrypt
KEY=\`curl --cacert ~/.step-ssh-templates/certs/root_ca.crt -s https://\${CA}/1.0/provisioners/\${KID}/encrypted-key | jq -r .key | step crypto jwe decrypt\`

echo '{"step": {"ssh":{"certType": "user", "keyID": "'$1'"}}, "ghu":"'$2'"}' | step crypto jwt sign --key <(echo $KEY) --iss "$ISS" --aud "https://\${CA}/1.0/sign" -sub $1 -exp $(date -v+5M +"%s")
`}</code></pre>
    <p>{`Here's the script used in concert with the GitHub SSH template above:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session"
      }}>{`$ TOKEN=$(./gh_token.sh carl tashian)
Please enter the password to decrypt the content encryption key: ...
$ echo $TOKEN | step crypto jwt inspect --insecure
{
  "header": {
    "alg": "ES256",
    "kid": "R0a0VGbbOe3j6ol5jOdV3qfiigjcezk0LG9K9Cp7mrg",
    "typ": "JWT"
  },
  "payload": {
    "aud": "https://localhost:4430/1.0/sign",
    "exp": 1606870196,
    "ghu": "tashian",
    "iat": 1606869896,
    "iss": "carl@smallstep.com",
    "jti": "6d62922871db260e54d97fe1f2561440177ae579e31baa954128e0fba155b4db",
    "nbf": 1606869896,
    "step": {
      "ssh": {
        "certType": "user",
        "keyID": "carl"
      }
    },
    "sub": "carl"
  },
  "signature": "RdYo70cCR7tMZBl8VLbxVpoCDlNED2wpRw8uPV1rNkDENBbXqml1h-TnWG23dJ4zKzPhAuO2Vk7HdOUC2q0mNg"
}
$ step ssh certificate carl github --token $TOKEN
✔ CA: https://localhost:4430
Please enter the password to encrypt the private key:
✔ Private Key: github
✔ Public Key: github.pub
✔ Certificate: github-cert.pub
✔ SSH Agent: yes
$ step ssh inspect github-cert.pub
github-cert.pub:
        Type: ecdsa-sha2-nistp256-cert-v01@openssh.com user certificate
        Public key: ECDSA-CERT SHA256:SdcgsNKaQbhYveanJYjmJk4eLN3+CBKUKkRGv4xYn5U
        Signing CA: ECDSA SHA256:MKwRQ/SDKk/pCJbbCk5bfhZACjSjv7uZXLyc5n4Wx6k
        Key ID: "carl"
        Serial: 886026644913671581
        Valid: from 2020-12-01T16:40:32 to 2020-12-02T08:41:32
        Principals:
                carl
        Critical Options: (none)
        Extensions:
                login@github.com tashian
`}</code></pre>
    <p>{`In production, you would not want to hand out your JWK provisioner password.
But you could create a token server that interoperates with `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` and injects secure values into the token. `}</p>
    <h4>{`SSH Group Accounts`}</h4>
    <p>{`Let's say your organization has shared group accounts on your hosts (eg. "dba" or "devops").
You'd want to be able to issue people in those groups a certificate that contains their user principals ("carl", "`}<a parentName="p" {...{
        "href": "mailto:carl@smallstep.com"
      }}>{`carl@smallstep.com`}</a>{`"),
plus principals for any group accounts they need access to.`}</p>
    <p>{`Let's use the OIDC ID token to inject additional principals into an SSH user certificate.`}</p>
    <p>{`First, configure a custom `}<inlineCode parentName="p">{`groups`}</inlineCode>{` claim with your identity provider, and add the claim to the payload of the ID token.
In this example, we're assuming the `}<inlineCode parentName="p">{`groups`}</inlineCode>{` claim contains a space-separated list of possible group accounts.`}</p>
    <p>{`Then, use the following template to merge the group accounts with the user's own principals (derived from the `}<inlineCode parentName="p">{`email`}</inlineCode>{` claim):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "type": {{ toJson .Type }},
  "keyId": {{ toJson .KeyID }},
  "principals": {{ toJson ((concat .Principals (splitList " " .Token.groups)) | uniq) }},
  "criticalOptions": {{ toJson .CriticalOptions }},
  "extensions": {{ toJson .Extensions }}
}
`}</code></pre>
    <p>{`This is a good example of the use of `}<a parentName="p" {...{
        "href": "https://masterminds.github.io/sprig/"
      }}>{`Sprig functions`}</a>{` in templates.`}</p>
    <p>{`Finally, test out your flow with `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ssh/login"
      }}><inlineCode parentName="a">{`step ssh login`}</inlineCode></a>{`, and use `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ssh/inspect"
      }}><inlineCode parentName="a">{`step ssh inspect`}</inlineCode></a>{` to check the principals of the certificate you're issued by the CA.`}</p>
    <h3>{`Even More Templates`}</h3>
    <p>{`To delve deeper into certificate templates and all the options, the best source
of information is the code docs for the `}<a parentName="p" {...{
        "href": "https://pkg.go.dev/go.step.sm/crypto/x509util"
      }}>{`go.step.sm/crypto/x509util
package`}</a>{`:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`The `}<a parentName="p" {...{
            "href": "https://pkg.go.dev/go.step.sm/crypto/x509util#Certificate"
          }}>{`Certificate`}</a>{`
object represents a certificate template with all its properties.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`The
`}<a parentName="p" {...{
            "href": "https://pkg.go.dev/go.step.sm/crypto/x509util#CertificateRequest"
          }}>{`CertificateRequest`}</a>{`
object represents all properties for a CSR template that can be used in
`}<a parentName="p" {...{
            "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/certificate/create"
          }}><inlineCode parentName="a">{`step certificate create --csr`}</inlineCode></a>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`All key usages and supported extended key usages without using
`}<inlineCode parentName="p">{`"unknownExtKeyUsage"`}</inlineCode>{` are available
`}<a parentName="p" {...{
            "href": "https://pkg.go.dev/go.step.sm/crypto/x509util#pkg-variables"
          }}>{`here`}</a>{`.`}</p>
      </li>
    </ul>
    <p>{`More information and more examples are available in the blog posts
`}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/blog/x509-certificate-flexibility/"
      }}>{`"Announcing X.509 Certificate Flexibility"`}</a>{`
and `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/blog/clever-uses-of-ssh-certificate-templates/"
      }}>{`"Clever Uses of SSH Certificate Templates"`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      